
<template>
	
  <div>
  	<Navbar />	
  	
    <div style="width: 100%;margin-top: 10px;padding-left: 10px;padding-right: 10px;">
    <table class="table table-striped">
            <thead>
                <tr>
                    <th style="    width: 200px;">
                    		<multiselect
									      v-model="selected"
									      :options="options">
									    </multiselect>
                    </th>	
    
    <th style="    width: 100px;"><input v-model="phone" style="height: 40px;background-color:whitesmoke" placeholder="Nhập số điện thoại"></th>
    <th  style="    width: 180px;"><button class="mt-4 btn-pers" v-on:click="timkiem">Tìm kiếm</button></th>
    <th  style="    width: 200px;"><button class="mt-4 btn-pers" v-on:click="export_exel">Xuất báo cáo</button></th>
    <th  style="    width: 180px;"><export-excel  v-if="is_show_button_download"
    class   = "mt-4 btn-pers"
    :data   = "json_data"
    :fields = "json_fields"
    worksheet = "SMS"
    name    = "report.xls">{{textdownload}}</export-excel></th>
				<th></th>
				<th></th>
				<th></th>
				<th></th>
				<th></th>
				<th></th>
				<th></th>
				<th></th>
				<th></th>
				                </tr>
				            </thead>
				        </table>
				        <br/>
				        <p style="text-align: center;">{{msginfo}}</p>
				        <table class="table table-striped table-bordered" style="  border-color: #dee2e6;    line-height: 2.5; width: 100%;">
				            <thead>
				                <tr style="background-color:whitesmoke">
				                    <th>Thời gian</th>
				                    <th>Khách hàng</th>
				                    <th>Số điện thoại</th>
				                    <th>Nội dung</th>
				                    
				                </tr>
				            </thead>
				            <tbody>
				                <tr v-for="user in users" :key="user.id">
				                  <td>{{user.time}}</td>
				                    <td>{{user.provider}}</td>
				                    <td>{{user.phone}}</td>
				                    <td>{{user.code}}</td>
				                    
				                </tr>
				            </tbody>
				        </table>
				        
				    </div>
				        
  </div>
</template>



<script>
import Navbar from './Navbar';
import { getAuthToken, logoutUser,search } from "../utils/auth";
import moment from "moment";
import Vue from 'vue'
import excel from "vue-excel-export";
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
const auth = getAuthToken();
export default {
  name: 'Sms',
  components: {
    Navbar,
    Multiselect
  },
  data () {
    return {
      msg: 'Contact us',
      counter: 0,
      value: '',
      phone: null,
      textdownload: 'Tải về',
      msginfo: null,
      is_show_button_download: false,
      selected: null,
      options: ['Tháng 1', 'Tháng 2', 'Tháng 3','Tháng 4', 'Tháng 5', 'Tháng 6','Tháng 7', 'Tháng 8', 'Tháng 9','Tháng 10', 'Tháng 11', 'Tháng 12'],
      theDate: moment().format("YYYY-MM-DD"),
      token: auth,
      users: [],
      json_fields: {
            'Thời gian': 'time',
            'Khách hàng': 'provider',
            'Số điện thoại': 'phone',
            'Nội dung': 'code'
        },
        json_data: [
        ],
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ]
    }
  },
  methods: {
  	export_exel(){
  			this.json_data = [];
  			this.msginfo  = "Đang truy vấn bạn vui lòng chờ đợi...";
  			search(this.selected, this.phone, true)
        .then((data) => {
        	console.log(data);
          if(data.error === 0){
          		this.is_show_button_download = true;
              this.json_data = [...data.findResult];  
              this.msginfo  = "";
          }else{
              this.json_data = [];  
              this.msginfo  = "Lỗi trong quá trình tìm kiếm";
          }
          
          
        })
        .catch((error) => console.log(error));
  			
  	},
    timkiem() {
        this.users = []
        this.is_show_button_download = false;
        this.msginfo  = "Đang truy vấn bạn vui lòng chờ đợi...";
        search(this.selected, this.phone)
        .then((data) => {
        	console.log(data);
          if(data.error === 0){
              this.users = [...data.findResult.slice(0, 20)];  
              this.json_data = [...data.findResult];  
              console.log(JSON.stringify(this.users));
              if(this.users.length === 0){
                this.msginfo  = "Không tìm thấy kết quả phù hợp";
              }else
                this.msginfo  = "";
          }else{
              this.users = [];  
              this.msginfo  = "Lỗi trong quá trình tìm kiếm";
          }
          
          // console.log(JSON.stringify(data));
          
        })
        .catch((error) => console.log(error));
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
	tbody tr:nth-child(odd) {
  background-color: #e2e2e2;
}

tbody tr:nth-child(even) {
  background-color: #ffffff;
}
  .list-item {
  margin-top: 50px;
}

.card {
  box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.16);
  width: 400px;
  padding: 20px 30px;
  margin-bottom: 30px;
}

select,input {
  border: none;
  padding: 10px 15px;
  background-color: #c1c1c1;
  border-radius: 10px;
}

/* CUSTOM STYLE */
#content {
  border: 1px solid lightgray;
  padding: 4rem 4rem;
  border-radius: 5px;
  background: #fefefe;
}

.background {
  width: 100vw;
  background: rgb(250, 250, 250);
}

.navbar {
  background: #fafafa;
  top: 0;
  width: 100vw;
  height: 70px;
  box-shadow: 6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
    22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
    100px 100px 80px rgba(0, 0, 0, 0.07);
}

.nav {
  width: 90%;
  min-width: 400px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #fafafa;
}

#name_project {
  font-weight: 700;
}

.container {
  width: 400px;
  max-width: 95%;
}

.input {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.input > label {
  text-align: start;
}

.input > input {
  margin-top: 6px;
  height: 38px !important;
}

/* From uiverse.io */
.btn-pers {
  position: relative;
  left: 50%;
  padding: 1em 2.5em;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 700;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  transform: translateX(-50%);
}

.btn-pers:hover {
  background-color: #198754;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #fff;
  transform: translate(-50%, -7px);
}

.btn-pers:active {
  transform: translate(-50%, -1px);
}

/*  */

.alternative-option {
  text-align: center;
}

.alternative-option > span {
  color: #0d6efd;
  cursor: pointer;
}

#sign_out {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
</style>