<template>
  <div class="about">
    <h1  style="margin-top: 40px">Đăng nhập hệ thống quản trị</h1>
    <div class="container">
    <form @submit.prevent="login">
      <div class="input">
        <label for="email">Địa chỉ email</label>
        <input
          class="form-control"
          type="text"
          name="email"
        />
      </div>
      <div class="input">
        <label for="password">Mật khẩu</label>
        <input
          class="form-control"
          type="password"
          name="password"
        />
      </div>
      <button type="submit" class="mt-4 btn-pers" id="login_button">
        Đăng nhập
      </button>
      <div
        class="alert alert-warning alert-dismissible fade show mt-5 d-none"
        role="alert"
        id="alert_1"
      >
        
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button>
      </div>
    </form>
  </div>
  </div>
</template>

<script>
import { loginUser,isLoggedIn } from "../utils/auth";

export default {
  data() {
    return {
      is_login: isLoggedIn(),
      email: "",
      password: "",
    };
  },
  watch: {
    is_login(newVal, oldVal) {
      console.log(newVal, oldVal)
    },
  },
  methods: {
    login(submitEvent) {
      this.email = submitEvent.target.elements.email.value;
      this.password = submitEvent.target.elements.password.value;
      loginUser(this.email, this.password)
        .then(() => {
          this.is_login = isLoggedIn();
          this.$router.push("/sms");
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode);
          console.log(errorMessage);
          let alert_1 = document.querySelector("#alert_1");
          alert_1.classList.remove("d-none");
          alert_1.innerHTML = errorMessage;
          console.log(alert_1);
        });
    },
    moveToRegister() {
      this.$router.push("/register");
    },
  },
};
</script>
<style type="text/css">
.container {
  text-align: center;
  margin: auto;
  width: 400px;
      padding-top: 50px;
}

.input {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.input > label {
  text-align: start;
}

.input > input {
  margin-top: 6px;
  height: 38px !important;
}

/* From uiverse.io */
.btn-pers {
  
  padding: 1em 2.5em;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 700;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.btn-pers:hover {
  background-color: #198754;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #fff;
  transform: translate(-0%, -1px);
}

.btn-pers:active {
  transform: translate(-0%, -1px);
}

/*  */

.alternative-option {
  text-align: center;
}

.alternative-option > span {
  color: #0d6efd;
  cursor: pointer;
}

#sign_out {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
</style>
