import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/components/Home'
import Login from '@/components/Login'
import Contacts from '@/components/Contacts'
import Sms from '@/components/Sms'
import Email from '@/components/Email'
import PbxConfig from '@/components/PbxConfig'
import PbxConfig2 from '@/components/PbxConfig2'
import Services from '../components/Services'


import Web from '@/components/Dropdown_folder/Web'
import Design from '@/components/Dropdown_folder/Design'
import Videos from '@/components/Dropdown_folder/Videos'
import { getAuthToken } from "../utils/auth";
import excel from "vue-excel-export";
Vue.use(Router)
Vue.use(excel)
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Sms,
      meta: {
        title: 'Home',
        authRequired: true,
      },
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/pbx/660c3be6b61b1d85dbe933a8',
      name: 'PbxConfig189',
      component: PbxConfig,
      meta: {
        pbx_id: `660c3be6b61b1d85dbe933a8`,
        authRequired: true,
      },
    },
    {
      path: '/pbx/660c4031ec0c42748c24bf4f',
      name: 'PbxConfigCmc',
      component: PbxConfig2,
      meta: {
        pbx_id:'660c4031ec0c42748c24bf4f',
        authRequired: true,
      },
    },
    {
      path: '/contacts',
      name: 'Contacts',
      component: Contacts,
      meta: {
        authRequired: true,
      },
    },
    {
      path: '/sms',
      name: 'Sms',
      component: Sms,
      meta: {
        title: 'Crm SMS',
        authRequired: true,
      },
    },
    {
      path: '/email',
      name: 'Email',
      component: Email,
      meta: {
        title: 'Crm Email',
        authRequired: true,
      },
    },
    {
      path: '/services',
      name: 'Services',
      component: Services
    },
    {
      path: '/services/web',
      name: 'Web',
      component: Web,
      meta: {
        authRequired: true,
      },

    },
    {
      path: '/services/design',
      name: Design,
      component: Design,
      meta: {
        authRequired: true,
      },
    },
    {
      path: '/services/videos',
      name: 'Videos',
      component: Videos,
      meta: {
        authRequired: true,
      },
    }
  ]
})
router.beforeEach((to, from, next) => {
  const auth = getAuthToken();

  if (to.matched.some((record) => record.meta.authRequired)) {
    if (auth) {
      next();
    } else {
      // alert("You've must been logged to access this area");
      router.push("/login");
    }
  } else {
    next();
  }
});
const DEFAULT_TITLE = 'CRM';
router.afterEach((to, from) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});
export default router;