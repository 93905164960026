/* eslint-disable */
import axios from 'axios'
import md5 from 'js-md5'
const REST_ENDPOINT = `https://api-crm.taonguyen.net`;
const AUTH_TOKEN_KEY = 'authToken'
const AUTH_INFO_KEY = 'authInfo'

export function loginUser(username, password) {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${REST_ENDPOINT}/api/account/login`,
                method: 'POST',
                data: {
                    username: username,
                    password: md5(password),
                    grant_type: 'password'
                }
            })
            if(res.data.error===0){
                setAuthToken(res.data.data.token);
            }
            resolve(Object.assign({}, res.data));
        } catch (err) {
            console.error('Caught an error during login:', err)
            reject(err)            
        }
    })
}

export function search_pbx(pbx_id) {
    let encodedToken =  localStorage.getItem(AUTH_TOKEN_KEY)
    console.log(`encodedToken`, encodedToken);
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${REST_ENDPOINT}/api/pbx?pbx_id=${pbx_id}`,
                method: 'GET',
                headers:{
                    "Authorization": encodedToken
                },
            })
            resolve(Object.assign({}, res.data));
        } catch (err) {
            console.error('Caught an error during login:', err)
            reject(err)            
        }
    })
}
export function update_pbx(pbx_id, body) {
    let encodedToken =  localStorage.getItem(AUTH_TOKEN_KEY)
    console.log(`encodedToken`, encodedToken);
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${REST_ENDPOINT}/api/update_pbx?pbx_id=${pbx_id}`,
                method: 'POST',
                headers:{
                    "Authorization": encodedToken
                },
                data: body
            })
            resolve(Object.assign({}, res.data));
        } catch (err) {
            console.error('Caught an error during login:', err)
            reject(err)            
        }
    })
}
export function search(month, phone, is_export = false) {
    let encodedToken =  localStorage.getItem(AUTH_TOKEN_KEY)
    console.log(`encodedToken`, encodedToken);
    return new Promise(async (resolve, reject) => {
        try {
            let res = await axios({
                url: `${REST_ENDPOINT}/api/search`,
                method: 'POST',
                headers:{
                    "Authorization": encodedToken
                },
                data: {
                    month,
                    phone,
                    is_export: (is_export)?1:0,
                }
            })
            resolve(Object.assign({}, res.data));
        } catch (err) {
            console.error('Caught an error during login:', err)
            reject(err)            
        }
    })
}

export function logoutUser() {
    clearAuthToken()
    return new Promise(async (resolve, reject) => {
            resolve({});
    });
}

export function setAuthToken(token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    localStorage.setItem(AUTH_TOKEN_KEY, token)
    
}
export function getAuthToken() {

    let encodedToken =  localStorage.getItem(AUTH_TOKEN_KEY)    
    return encodedToken;
}

export function clearAuthToken() {
    console.log('clearAuthToken');
    axios.defaults.headers.common['Authorization'] = ''
    localStorage.removeItem(AUTH_TOKEN_KEY)
}

export function isLoggedIn() {
    let authToken = getAuthToken()
    console.log('authToken', authToken);
    return !!authToken
}
/* eslint-enable */
