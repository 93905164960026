<template>
    <nav id="vue">
       <div class="menu-item" id="name_project">
        <img src="./img/logo.png" width="30" class="me-2" alt="" />
      </div>
        <div class="menu-item"  v-if="isLogin()"><router-link to="/sms">Sms</router-link></div>
        <div class="menu-item"  v-if="isLogin()"><router-link to="/email">Email CSKH</router-link></div>
        
        <Services title="CallCenter"  v-if="isLogin()"/> 
        <!-- <div class="menu-item" v-if="isLogin()"><router-link to="/contacts">System && Monitor</router-link></div> -->
        <div class="menu-item" v-if="isLogin()"  @click="signOut">Đăng xuất</div>
    </nav>
</template>

<script>
import Services from '../components/Services'
import { logoutUser, isLoggedIn } from "../utils/auth";
export default {
  name: 'navbar',
  data() {
    return {
      is_login: isLoggedIn(),
    };
  },
  methods: {
    isLogin(){
      return isLoggedIn();
    },
    signOut() {
      logoutUser()
        .then(() => {
          console.log("Sign Out completed");
          this.$router.push("/login");
        })
        .catch((error) => console.log(error));
    },
  },
  components: {
    Services
  },

}
</script>
<style>
nav {
  width: 100vw;
  background-color: #0d0d0e;
  display: flex;
  align-items: center;
  justify-content: center;
}
nav .menu-item {
  color: #FFF;
  padding: 25px 40px;
  position: relative;
  text-align: center;
  border-bottom: 5px solid transparent;
  display: flex;
  transition: 0.4s;
}
nav .menu-item.active,
nav .menu-item:hover {
  background-color: #0d0d0e;
  border-bottom-color: #bb3030;
}
nav .menu-item a {
  color: inherit;
  text-decoration: none;
}
</style>