
<template>
	
  <div>
  	<Navbar />	
    <br/>
  	<h1 style="    text-align: left;
    margin-left: 10px;">Cấu hình hệ thống cho Tổng đài </h1>
    <div style="width: 100%;margin-top: 10px;padding-left: 10px;padding-right: 10px;">
    <table class="table table-striped" style="width: 100%">
            <thead>
                <tr>
                    <th style=" text-align: left;   width: 200px;">
                    	Tên miền hệ thống:
                    </th>	
                    <th style="  text-align: left;  width: 100px;">
                        <input v-model="domain" style="height: 40px;background-color:whitesmoke" placeholder="Nhập domain">
                    </th>
                    <th style="   text-align: left; width: 200px;">
                      IP Public:
                    </th> 
                    <th style="   text-align: left; width: 100px;">
                        <input v-model="public_ip" style="height: 40px;background-color:whitesmoke" placeholder="IP">
                    </th>
               </tr>
               <tr>
                    <th style="   text-align: left; width: 200px;">
                      Port Public:
                    </th> 
                    <th style=" text-align: left;   width: 100px;">
                        <input v-model="dmz_port" style="height: 40px;background-color:whitesmoke" placeholder="5080">
                    </th>
                    <th style="  text-align: left;  width: 200px;">
                      Port Internal:
                    </th> 
                    <th style="  text-align: left;  width: 100px;">
                        <input v-model="inter_port" style="height: 40px;background-color:whitesmoke" placeholder="5060">
                    </th>
               </tr>
               <tr>
                    <th style="  text-align: left;  width: 200px;">
                      Port Websocket:
                    </th> 
                    <th style="   text-align: left; width: 100px;">
                        <input v-model="web_socket_port" style="height: 40px;background-color:whitesmoke" placeholder="8443">
                    </th>
                    <th style="  text-align: left;  width: 200px;">
                      Port Sturn:
                    </th> 
                    <th style="  text-align: left;  width: 100px;">
                        <input v-model="sturn_port" style="height: 40px;background-color:whitesmoke" placeholder="1443">
                    </th>
               </tr>
               <tr>
                    <th style="  text-align: left;  width: 200px;">
                      Port event:
                    </th> 
                    <th style="  text-align: left;  width: 100px;">
                        <input v-model="socket_port" style="height: 40px;background-color:whitesmoke" placeholder="9983">
                    </th>
                    <th style="  text-align: left;  width: 200px;">
                      Port web:
                    </th> 
                    <th style="  text-align: left;  width: 100px;">
                        <input v-model="web_port" style="height: 40px;background-color:whitesmoke" placeholder="443">
                    </th>
               </tr>

	           </thead>
	        </table>
	    </div>
      <p style="    text-align: left;
    font-weight: bold;
    margin-top: 20px;
    margin-left: 10px;
    margin-bottom: 10px;
">Danh sách portal </p> 
    <table class="table table-striped table-bordered" style="  border-color: #dee2e6;    line-height: 2.5; width: 100%;">
      <tr>
        <td style="width: 50%;">
          <table class="table table-striped table-bordered" style="  border-color: #dee2e6;    line-height: 2.5; width: 100%;">
                    <thead>
                        <tr style="background-color:whitesmoke">
                            <th>Name</th>
                            <th>Prefix</th>
                            <th>hotline</th>
                            <th>Action</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="user in portals" :key="user.id">
                          <td style="text-align: left;
    padding-left: 10px;" @click="show_hotline(user.hotline)">{{user.name}}</td>
                            <td>{{user.prefix}}</td>
                            <td>{{user.hotline}}</td>
                            <td><a style="    color: blue;
    font-weight: bold;" @click="show_hotline(user.hotline)">Chi tiết </a></td>
                            
                        </tr>
                    </tbody>
          </table>
        </td>
        <td style="width: 1%;">
          
        </td>
        <td style="width: 49%;background-color: #e2e2e2; vertical-align:top">
          <div style="text-align: left;
    margin-left: 10px;
    font-weight: bold;">Cấu hình portal: {{current_portal.name}}</div>
    <table class="table table-striped" style="width: 100%; padding-left: 10px;">
            <thead>
                <tr>
                    <th style=" text-align: left;   width: 200px;">
                      Địa chỉ IP (Sử dụng để thực hiện gọi ra):
                    </th> 
                    <th style="  text-align: left;  width: 100px;">
                        <input v-model="current_portal.ip" style="width: 100%;height: 40px;background-color:whitesmoke" placeholder="IP:port">
                    </th>
               </tr>
               <tr>
                    <th style=" text-align: left;   width: 200px;">
                      Cấu hình điều hướng hotline
                    </th> 
                    <th style="width: 50%;  text-align: left;"><multiselect
                        v-model="current_portal.config.type"
                        :options="options">
                      </multiselect></th>
               </tr>
             </thead>
           </table>
          <table class="table table-striped table-bordered" style="  border-color: #dee2e6;    line-height: 2.5; width: 100%;">
              <thead>
                        <tr style="background-color:whitesmoke">
                            
                            
                            
                        </tr>
                    </thead>
                    <tbody>
                       <tr v-if="current_portal.config.type==='Web Event'">
                          <td>
                          <input v-model="current_portal.config.web_event" style="width:100%; height: 40px;background-color:whitesmoke" placeholder="Nhập địa chỉ nhận web event">
                        </td>
                    </tr>
                    <tr v-if="current_portal.config.type==='Chuyển tiếp'">
                          <td>
                          <input v-model="current_portal.config.forward_uri" style="width:100%; height: 40px;background-color:whitesmoke" placeholder="Địa chỉ cần điều hướng">
                        </td>
                    </tr>
                    <tr v-if="current_portal.config.type==='Phím bấm'">
                          <td>
                          <table class="table table-striped" style="width: 100%">
            <thead>
                <tr>
                    <th style=" text-align: left;   width: 200px;">
                      Phím 1:
                    </th> 
                    <th style="  text-align: left;  width: 100px;">
                        <input v-model="current_portal.config.key_1" style="height: 40px;background-color:whitesmoke" placeholder="">
                    </th>
                    <th style="   text-align: left; width: 200px;">
                      Phím 2:
                    </th> 
                    <th style="   text-align: left; width: 100px;">
                        <input v-model="current_portal.config.key_2" style="height: 40px;background-color:whitesmoke" placeholder="">
                    </th>
               </tr>
               <tr>
                    <th style="   text-align: left; width: 200px;">
                      Phím 3:
                    </th> 
                    <th style=" text-align: left;   width: 100px;">
                        <input v-model="current_portal.config.key_3" style="height: 40px;background-color:whitesmoke" placeholder="">
                    </th>
                    <th style="  text-align: left;  width: 200px;">
                      Phím 4:
                    </th> 
                    <th style="  text-align: left;  width: 100px;">
                        <input v-model="current_portal.config.key_4" style="height: 40px;background-color:whitesmoke" placeholder="">
                    </th>
               </tr>
               <tr>
                    <th style="  text-align: left;  width: 200px;">
                      Phím 5:
                    </th> 
                    <th style="   text-align: left; width: 100px;">
                        <input v-model="current_portal.config.key_5" style="height: 40px;background-color:whitesmoke" placeholder="">
                    </th>
                    <th style="  text-align: left;  width: 200px;">
                      Phím 6:
                    </th> 
                    <th style="  text-align: left;  width: 100px;">
                        <input v-model="current_portal.config.key_6" style="height: 40px;background-color:whitesmoke" placeholder="">
                    </th>
               </tr>
               <tr>
                    <th style="  text-align: left;  width: 200px;">
                      Phím 7:
                    </th> 
                    <th style="  text-align: left;  width: 100px;">
                        <input v-model="current_portal.config.key_7" style="height: 40px;background-color:whitesmoke" placeholder="">
                    </th>
                    <th style="  text-align: left;  width: 200px;">
                      Phím 8:
                    </th> 
                    <th style="  text-align: left;  width: 100px;">
                        <input v-model="current_portal.config.key_8" style="height: 40px;background-color:whitesmoke" placeholder="">
                    </th>
               </tr>
              <tr>
                    <th style="  text-align: left;  width: 200px;">
                      Phím 9:
                    </th> 
                    <th style="  text-align: left;  width: 100px;">
                        <input v-model="current_portal.config.key_9" style="height: 40px;background-color:whitesmoke" placeholder="">
                    </th>
                    <th style="  text-align: left;  width: 200px;">
                      Phím *:
                    </th> 
                    <th style="  text-align: left;  width: 100px;">
                        <input v-model="current_portal.config.key_sao" style="height: 40px;background-color:whitesmoke" placeholder="">
                    </th>
               </tr>
             </thead>
          </table>
                        </td>
                    </tr>
                    </tbody>
          </table>
        </td>
      </tr>
    </table>
      

      <br/>

      <table class="table table-striped table-bordered" style="  border-color: #dee2e6;    line-height: 2.5; width: 100%;">
      <tr>
        <td style="width: 20%;">
          
        </td>
        <td style="width: 50%;    text-align: left;">
          <button class="mt-4 btn-pers" v-on:click="update_config">Lưu cấu hình</button><button class="mt-4 btn-pers"> <a href="https://crm-tn2024.s3.ap-southeast-1.amazonaws.com/0bf8c975-9e72-4fa8-bb8d-8ddf7ad90531/70e29ccf3bc7d0d76bc052f01c9e063d.enc" target="_blank" style="text-decoration: none;"> Tải cấu hình</a></button>
        </td>
      </tr></table>
  </div>
</template>
<script>
import Navbar from './Navbar';
import { getAuthToken, logoutUser,search, search_pbx, update_pbx } from "../utils/auth";
import moment from "moment";
import Vue from 'vue'
import excel from "vue-excel-export";
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
const auth = getAuthToken();
export default {
  name: 'Sms',
  components: {
    Navbar,
    Multiselect
  },
  mounted: function () {
      console.log(this.$route);
      this.pbx_id = this.$route.meta.pbx_id;
      search_pbx(this.pbx_id)
        .then((data) => {
          console.log(data);
          if(data.error === 0){
                this.domain = data.detail.domain;
                this.public_ip = data.detail.public_ip;
                this.inter_port = data.detail.inter_port;
                this.dmz_port = data.detail.dmz_port;
                this.web_socket_port = data.detail.web_socket_port;
                this.socket_port = data.detail.socket_port;
                this.sturn_port =  data.detail.sturn_port;
                this.web_port = data.detail.web_port;
                this.portals = [...data.detail.portals];
                this.current_portal = this.portals[0];
          }
        }).catch((error) => console.log(error));
  },
  data () {
    return {
        current_portal: {
            name: '',
            prefix: '',
            hotline: '',
            ip:'',
            config:{
              type:'Đổ chuông',
              web_event: '',
              forward_uri: '',
              key_1: '',
              key_2: '',
              key_3: '',
              key_4: '',
              key_5: '',
              key_6: '',
              key_7: '',
              key_8: '',
              key_9: '',
              key_sao: '',
            }
        },
      
      domain: "", 
      public_ip:"",
      inter_port:0,
      dmz_port:0, 
      web_socket_port:0,
      socket_port:0,
      sturn_port: 0,
      web_port: 0,
      //hết config
      pbx_id: ``,
      msg: 'Contact us',
      counter: 0,
      phone: null,
      textdownload: 'Tải về',
      msginfo: null,
      is_show_button_download: false,
      selected: 'Đổ chuông',
      options: ['Đổ chuông', 'Phím bấm', 'Web Event','Chuyển tiếp'],
      theDate: moment().format("YYYY-MM-DD"),
      token: auth,
      portals: [
      ],
      json_fields: {
            'Thời gian': 'time',
            'Khách hàng': 'provider',
            'Số điện thoại': 'phone',
            'Nội dung': 'code'
        },
        json_data: [
        ],
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ]
    }
  },
  methods: {
    save_config(){

    },
    update_config(){
      const body ={
        domain: this.domain,
        public_ip: this.public_ip,
        inter_port: this.inter_port,
        dmz_port: this.dmz_port,
        web_socket_port: this.web_socket_port,
        socket_port: this.socket_port,
        sturn_port: this.sturn_port,
        web_port: this.web_port,
        portals: this.portals

      } 
      update_pbx(this.pbx_id, body)
        .then((data) => {
          console.log(data);
          if(data.error === 0){
               alert("Cập nhật thành công");
          }
        }).catch((error) => console.log(error));


    },
    delete_hotline(hotline){
      alert("Delete hotline" + hotline);
    },
    show_hotline(hotline){
      // alert("show info hotline"  + hotline);
      for(let portal of this.portals){
        if(portal.hotline === hotline){
            this.current_portal = Object.assign({}, portal);
        }
      }
    },
  	export_exel(){
  			this.json_data = [];
  			this.msginfo  = "Đang truy vấn bạn vui lòng chờ đợi...";
  			search(this.selected, this.phone, true)
        .then((data) => {
        	console.log(data);
          if(data.error === 0){
          		this.is_show_button_download = true;
              this.json_data = [...data.findResult];  
              this.msginfo  = "";
          }else{
              this.json_data = [];  
              this.msginfo  = "Lỗi trong quá trình tìm kiếm";
          }
          
          
        })
        .catch((error) => console.log(error));
  			
  	},
    timkiem() {
        this.users = []
        this.is_show_button_download = false;
        this.msginfo  = "Đang truy vấn bạn vui lòng chờ đợi...";
        search(this.selected, this.phone)
        .then((data) => {
        	console.log(data);
          if(data.error === 0){
              this.users = [...data.findResult.slice(0, 20)];  
              this.json_data = [...data.findResult];  
              console.log(JSON.stringify(this.users));
              if(this.users.length === 0){
                this.msginfo  = "Không tìm thấy kết quả phù hợp";
              }else
                this.msginfo  = "";
          }else{
              this.users = [];  
              this.msginfo  = "Lỗi trong quá trình tìm kiếm";
          }
          
          // console.log(JSON.stringify(data));
          
        })
        .catch((error) => console.log(error));
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
	tbody tr:nth-child(odd) {
  background-color: #e2e2e2;
}

tbody tr:nth-child(even) {
  background-color: #ffffff;
}
  .list-item {
  margin-top: 50px;
}

.card {
  box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.16);
  width: 400px;
  padding: 20px 30px;
  margin-bottom: 30px;
}

select,input {
  border: none;
  padding: 10px 15px;
  background-color: #c1c1c1;
  border-radius: 10px;
}

/* CUSTOM STYLE */
#content {
  border: 1px solid lightgray;
  padding: 4rem 4rem;
  border-radius: 5px;
  background: #fefefe;
}

.background {
  width: 100vw;
  background: rgb(250, 250, 250);
}

.navbar {
  background: #fafafa;
  top: 0;
  width: 100vw;
  height: 70px;
  box-shadow: 6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
    22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
    100px 100px 80px rgba(0, 0, 0, 0.07);
}

.nav {
  width: 90%;
  min-width: 400px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #fafafa;
}

#name_project {
  font-weight: 700;
}

.container {
  width: 400px;
  max-width: 95%;
}

.input {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.input > label {
  text-align: start;
}

.input > input {
  margin-top: 6px;
  height: 38px !important;
}

/* From uiverse.io */
.btn-pers {
  position: relative;
  left: 50%;
  padding: 1em 2.5em;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 700;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  transform: translateX(-50%);
}

.btn-pers:hover {
  background-color: #198754;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #fff;
  transform: translate(-50%, -7px);
}

.btn-pers:active {
  transform: translate(-50%, -1px);
}

/*  */

.alternative-option {
  text-align: center;
}

.alternative-option > span {
  color: #0d6efd;
  cursor: pointer;
}

#sign_out {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
</style>