<template>
    <div class="menu-item" @click="isOpen = !isOpen" >
        <a href ='#'>
            {{ title }}
        </a>
        <svg viewBox="0 0 1030 638" width="10">
            <path d="M1017 68L541 626q-11 12-26 12t-26-12L13 68Q-3 49 6 24.5T39 0h952q24 0 33 24.5t-7 43.5z" fill="#FFF"></path>
        </svg>
        <transition name="fade" apear>
         <div class="sub-menu" v-if="isOpen">
            <div class="menu-item">
              <router-link to="/pbx/660c3be6b61b1d85dbe933a8">Kdata - 189</router-link>
            </div>
            <div class="menu-item">
              <router-link to="/pbx/660c4031ec0c42748c24bf4f">CMC - 172</router-link>
            </div>
        </div>
        </transition>
    </div>
</template>

<script>
export default {
  name: 'services',
  props: ['title'],
  data () {
    return {
      isOpen: false,
      }
    }
}
</script>

<style>
nav .menu-item svg {
    width: 10px;
    margin-left: 10px;
}
nav .menu-item .sub-menu {
  position: absolute;
  background-color: #222;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  border-radius: 0px 0px 16px 16px;
}
.fade-enter-active,
.fade-leave-active {
  transition: all .5s ease-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.sub-menu {
  cursor: pointer;
}
</style>
